import(/* webpackMode: "eager" */ "/home/runner/work/kc-fonden-t3-mui/kc-fonden-t3-mui/node_modules/.pnpm/next@15.0.0-canary.65_@babel+core@7.25.2_@opentelemetry+api@1.9.0_babel-plugin-react-compiler_tnrteg6m6s4omjbc536yr3ycju/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/kc-fonden-t3-mui/kc-fonden-t3-mui/node_modules/.pnpm/next@15.0.0-canary.65_@babel+core@7.25.2_@opentelemetry+api@1.9.0_babel-plugin-react-compiler_tnrteg6m6s4omjbc536yr3ycju/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/kc-fonden-t3-mui/kc-fonden-t3-mui/src/sections/home/HomeHero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/kc-fonden-t3-mui/kc-fonden-t3-mui/src/shadcn/navigation/home-footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/kc-fonden-t3-mui/kc-fonden-t3-mui/src/shadcn/navigation/language-popover.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModeToggle"] */ "/home/runner/work/kc-fonden-t3-mui/kc-fonden-t3-mui/src/shadcn/theme-provider/toggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion","AccordionItem","AccordionTrigger","AccordionContent"] */ "/home/runner/work/kc-fonden-t3-mui/kc-fonden-t3-mui/src/shadcn/ui/accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownMenu","DropdownMenuTrigger","DropdownMenuContent","DropdownMenuLabel","DropdownMenuSeparator","DropdownMenuItem"] */ "/home/runner/work/kc-fonden-t3-mui/kc-fonden-t3-mui/src/shadcn/ui/dropdown-menu.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/kc-fonden-t3-mui/kc-fonden-t3-mui/src/shadcn/ui/sheet.tsx");
