'use client';

import { PATH_DASHBOARD, PATH_PAGE } from '~/routes/paths';
import { Button } from '~/shadcn/ui/button';
import Link from 'next/link';
import { FileText, Info, Mail } from 'lucide-react';

// ----------------------------------------------------------------------

type Props = {
  dict: Dictionary;
  lang: AppLocale;
};

export default function HomeHero({ dict, lang }: Props) {
  return (
    <>
      <div className="w-full relative bg-gradient-to-b/HomeColorPresets.tsx py-12 min-h-[90vh] from-background to-gray-100 dark:to-background dark:from-gray-900">
        <div className="flex flex-col justify-center">
          <div className="max-w-xl mx-auto flex flex-col items-center justify-center text-center py-24">
            <div>
              <h2 className="text-2xl font-bold text-red-600 dark:text-red-300 dark:font-semibold">
                Stiftelsen
              </h2>
            </div>

            <div>
              <div className="bg-gradient-to-r from-primary to-warning bg-clip-text text-transparent text-4xl md:text-6xl font-extrabold py-4">
                Kempe Carlgrenska
              </div>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-red-600 dark:text-red-300 dark:font-semibold">
                Fonden
              </h2>
            </div>

            <div>
              <p className="my-3 text-base">
                {dict['En allmännyttig stiftelse.']}
              </p>
            </div>

            <div>
              <div className="space-y-4">
                <Button className="w-full" size="lg" variant="default" asChild>
                  <Link href={PATH_DASHBOARD.root(lang)}>
                    <FileText className="mr-2" />
                    {dict['Ansökningar och rapporter']}
                  </Link>
                </Button>
                <div className="space-y-0 space-x-2 flex flex-row">
                  <Button size="lg" variant="outline" asChild>
                    <Link href={PATH_PAGE.about(lang)}>
                      <Info className="mr-2" />
                      {dict['Om oss']}
                    </Link>
                  </Button>
                  <Button size="lg" variant="outline" asChild>
                    <Link href={PATH_PAGE.contact(lang)}>
                      <Mail className="mr-2" />
                      {dict['Kontakt']}
                    </Link>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
